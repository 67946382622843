
import { IonToggle, toastController, IonIcon, IonButton } from '@ionic/vue';
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import DataTable from 'datatables.net-dt'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { DataService } from '@/services/cities/data.service';
import { createOutline } from 'ionicons/icons';
import { useRouter } from 'vue-router';

export default defineComponent({
    name:'CitiesComponent',
    components:{
        IonToggle, IonButton, IonIcon
    },
    setup(){
        const dataService = new DataService()
        const listOperators = ref()
        const router = useRouter()
        const getList = async () => {
            listOperators.value = await dataService.getData()
        }
        onMounted(() => {
            getList().then((val) => {
                nextTick(() => {
                    new DataTable('#citiesTable', {
                        searching: true,
                        paging:true,
                        columnDefs:[{
                            "targets":3
                        }],
                    });
                });
            });
        })
        function edit(id:string, name:string) {
            router.push({
                name:'editCityPage',
                params:{
                    id:id,
                    name:name
                }
            })
        }
        return {
            listOperators,
            createOutline,
            dataService,
            edit
        }
    },
    methods:{
        editProfile(key:string){
            this.$router.push({
                name:'editCategoryPage',
                params:{
                    id:key
                }
            })
        },
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
                message: msg,
                duration: 1500,
                position: position,
                cssClass: 'custom-toast'
            });

            await toast.present();
        },
        changeStatus(key:string, event:any){
            const result = this.dataService.changeStatus(key, event.detail.checked);
            result.then((response) => {
                if(response.status == 200) {
                    this.presentToast("bottom", 'Ciudad modificada')
                }
            });
        }
    }
})
