
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { DataService } from '@/services/categories/data.service';
import { idCard } from 'ionicons/icons';
export default defineComponent({
    name:"newCategoryPage",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, IonButton
    },
    setup() {
        const dataService = new DataService()
        const formData = new FormData()
        const selectedFile = ref({
            "file": null,
            "fileName": "",
            "ext" : ""
        });
        
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const operatorData = ref({
            nombre: "",
        });
        const fileBase = ref()
        
        const handleFileChange = (event:any) => {      
            formData.append('nombre', operatorData.value.nombre);  
            if(event.target.files && event.target.files[0]) {
                selectedFile.value.file = event.target.files[0];
                selectedFile.value.ext = event.target.files[0].name.split(".").pop();
                selectedFile.value.fileName = event.target.files[0].name.split(".").shift();                
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = async () => {
                    fileBase.value = reader.result;         
                }
                
            }         
        };

        onMounted(() => {
            console.log(id);
            dataService.getData(id).then((response) => {
                console.log(response.data)
                operatorData.value.nombre = response.data.categories.nombre
            })
        });

        return {
            router,
            operatorData,
            dataService,
            selectedFile,
            handleFileChange,
            formData,
            fileBase,
            id
        }
    },
    methods:{       
        
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
            message: msg,
            duration: 1500,
            position: position,
            });

            await toast.present();
        },
        async saveCategory () {               
            const response = await this.dataService.saveCategory({
                "id" : this.id,
                "nombre" : this.operatorData.nombre,
                "icono" : this.fileBase,
                "file" : this.selectedFile.file,
                "filename" : this.selectedFile.fileName,
                "ext" : this.selectedFile.ext
            });
            if(response.status == 200) {
                this.presentToast("bottom", "Categoría guardada");
                setTimeout(() => {
                    this.$router.back();
                }, 1000);
            } else {
                this.presentToast("bottom", "Problemas con la imagen");
            }
        }
    }
})
