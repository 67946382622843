import axios from 'axios';

export class DataService {
    private apiUrl = process.env.VUE_APP_URL_FIREBASE
    async fetchData() {
        const response = await axios.get(`${this.apiUrl}/getProfiles`);
        return response.data.profiles;
    }

    async changeStatus(operatorId:string, status:boolean) {
        const response = await axios.post(`${this.apiUrl}/changeStatusProfile`,{
            operatorId: operatorId,
            status: status
        });
        return response;
    }
}