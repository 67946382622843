
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, IonBackButton, IonButtons } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { DataService } from '@/services/cities/data.service';
export default defineComponent({
    name:"newCityPage",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, IonButton
    },
    setup() {
        const formData = new FormData()
        const dataService = new DataService()
        const router = useRouter()
        const operatorData = ref({
            nombre: "",
        });

        return {
            router,
            operatorData,
            dataService,
            formData
        }
    },
    methods:{       
        
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
                message: msg,
                duration: 800,
                position: position,
            });

            return toast;
        },
        async saveCategory () { 
                               
            const response = await this.dataService.saveCity({
                "nombre" : this.operatorData.nombre,
            });
            if(response.status == 200) {                
                setTimeout(async () => {
                    (await this.presentToast("bottom", "Ciudad guardada")).present(); 
                    (await this.presentToast("bottom", "")).dismiss();
                    this.$router.push({
                        name:'CitiesPage',
                        replace:true
                    })
                }, 1000);
            } else {
                (await this.presentToast("bottom", "Problemas con la imagen")).present();
            }
        }
    }
})
