
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, 
    IonInput, IonItem, IonSelect, IonSelectOption, loadingController } from '@ionic/vue';
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router';
import { Operator } from '@/interfaces/OperatorInterface'
export default defineComponent({
    name:"editOperator",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, 
        IonButton, IonSelect, IonSelectOption
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const getData = () => {
            return axios.get(
                `${process.env.VUE_APP_URL_FIREBASE}/getOperator`,
                {
                    params:{
                        id:route.params.id
                    }
                }
            )
        }
        const getList = () => {
            return axios.get(
                `${process.env.VUE_APP_URL_FIREBASE}/getProfiles`
            )
        }
        const getListCity = () => {
            return axios.get(
                `${process.env.VUE_APP_URL_FIREBASE}/getCities`
            )
        }
        const profilesList = ref([] as any)
        const cityList = ref([] as any)
        const operatorData = ref({} as Operator)

        onMounted(async () => {
            const loading = await loadingController.create({ message: 'Cargando' })
            loading.present()
            getListCity()
            .then((response) => {
                cityList.value = response.data.cities
            })  
            await nextTick()
            getList()
            .then((response) => {
                profilesList.value = response.data.profiles
            })     
            await nextTick()
            getData()
            .then((response) => {
                operatorData.value = response.data.operator
                operatorData.value.id = response.data.id
            })        
            loading.dismiss()
        })

        return {
            route,
            router,
            operatorData,
            profilesList,
            cityList
        }
    },
    methods:{
        selectProfile(event:any){
            this.operatorData.perfil = event.detail.value
        },
        async presentToast(position:'bottom') {
            const toast = await toastController.create({
            message: 'Operador modificado exitosamente',
            duration: 1500,
            position: position,
            cssClass: 'custom-toast',
            });

            await toast.present();
        },
        async saveOperator(){
            return await axios.post(
                `${process.env.VUE_APP_URL_FIREBASE}/saveOperator`,
                this.operatorData
            ).then((response) => {
                if(response.status == 200) {
                    this.presentToast("bottom")
                    this.$router.push({
                        name:'OperadoresPage',
                        replace:true
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
})
