
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { DataService } from '@/services/categories/data.service';
export default defineComponent({
    name:"newCategoryPage",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, IonButton
    },
    setup() {
        const formData = new FormData()
        const selectedFile = ref({
            "file": null,
            "fileName": "",
            "ext" : ""
        });
        const dataService = new DataService()
        const router = useRouter()
        const operatorData = ref({
            nombre: "",
        });
        const fileBase = ref()
        
        const handleFileChange = (event:any) => {      
            formData.append('nombre', operatorData.value.nombre);  
            if(event.target.files && event.target.files[0]) {
                console.log(event.target.files[0]);
                selectedFile.value.file = event.target.files[0];
                selectedFile.value.ext = event.target.files[0].name.split(".").pop();
                selectedFile.value.fileName = event.target.files[0].name.split(".").shift();                
                // const file = event.target.files[0],
                //     fileName = file.name.split(".").shift();
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = async () => {
                    fileBase.value = reader.result;       
                    //formData.append('icono', fileBase.value);           
                }
                
            }         
        };

        return {
            router,
            operatorData,
            dataService,
            selectedFile,
            handleFileChange,
            formData,
            fileBase
        }
    },
    methods:{       
        
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
            message: msg,
            duration: 1500,
            position: position,
            });

            await toast.present();
        },
        async saveCategory () {                     
            const response = await this.dataService.saveCategory({
                "nombre" : this.operatorData.nombre,
                "icono" : this.fileBase,
                "file" : this.selectedFile.file,
                "filename" : this.selectedFile.fileName,
                "ext" : this.selectedFile.ext
            });
            if(response.status == 200) {
                this.presentToast("bottom", "Categoría guardada");
                this.$router.back();
            } else {
                this.presentToast("bottom", "Problemas con la imagen");
            }
        }
    }
})
