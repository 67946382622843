
import { IonHeader, IonItem, IonTitle, IonToolbar, IonMenu, IonText, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { apps, construct, people, business, person, personAdd, key, businessOutline } from 'ionicons/icons';
export default defineComponent({
    name:'MenuComponent',
    components:{
        IonHeader, IonItem, IonTitle, IonToolbar, IonMenu, IonText, IonIcon
    },
    setup(){
        return {
            apps,
            construct,
            people,
            business,
            person,
            personAdd,
            key,
            businessOutline
        }
    },
    methods:{
        goCategories(){
            this.$router.push({
                name:'ListCategories',
                replace:false
            })
            // setTimeout(() => {
            //     window.location.reload()
            // }, 1000)
        },
        goServices(){
            this.$router.push({
                name:'ServiciosPage',
                replace:true
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        },
        goUsers(){
            this.$router.push({
                name:'UsuariosPage'
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        },
        goCompanies(){
            this.$router.push({
                name:'EmpresasPage'
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        },
        goPatrones(){
            this.$router.push({
                name:'CitiesPage'
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        },
        goEsoecialistas(){
            this.$router.push({
                name:'EspecialistasPage'
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        },
        goOperadores(){
            this.$router.push({
                name:'OperadoresPage'
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        },
        goPerfilesSeguridad(){
            this.$router.push({
                name:'PerfilesSeguridadPage'
            })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
    }
})
