
import { IonButton, IonContent, IonHeader, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { personCircleOutline} from 'ionicons/icons';
import CategoryComponent from '@/components/categories/Category.vue'
export default defineComponent({
    name: 'CategoryPage',
    components:{
        IonContent, IonHeader, IonPage, IonButton, CategoryComponent
    },
    setup(){
        return {
            personCircleOutline
        }
    },
    methods:{
        newCategory(){
            this.$router.push({
                name:'newCategoryPage',
            })
        }
    }
})
