import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getFunctions } from 'firebase/functions';

export const firebaseApp = initializeApp({
  // your application settings
  apiKey: "AIzaSyBR73J69WH8XWrxwMGoe7DTPrGfzr_mX7k",
  authDomain: "iduit-91cb7.firebaseapp.com",
  databaseURL: "https://iduit-91cb7-default-rtdb.firebaseio.com",
  projectId: "iduit-91cb7",
  storageBucket: "iduit-91cb7.appspot.com",
  messagingSenderId: "843370321330",
  appId: "1:843370321330:web:2e2aabbfec1f2f6acc00ab",
  measurementId: "G-PBKW4QESS8"
});

export const db = getDatabase(firebaseApp)
export const functions = getFunctions(firebaseApp)