import moment from 'moment';

const filters = {
    formatPrice(value: any) {
        const val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    formatDate(value: number) {
        if(value) {
            return moment(value).format("DD/MM/YYYY")
        }
    },
    formatTime(value: number) {
        if(value) {
            return moment(value).format("hh:mm:ss")
        }
    },
    diffTime(started: number, finished:number) {
        const a = moment(started);
        const b = moment(finished);
        return b.to(a, true)
    }
}
export default filters;