
import { IonToggle, toastController, IonIcon } from '@ionic/vue';
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import DataTable from 'datatables.net-dt'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { DataService } from '@/services/categories/data.service';
import { pencilOutline } from 'ionicons/icons';

export default defineComponent({
    name:'CategoryComponent',
    components:{
        IonToggle, IonIcon
    },
    setup(){
        const dataService = new DataService()
        const listOperators = ref()
        const getList = async () => {
            listOperators.value = await dataService.fetchData()
        }
        onMounted(() => {
            getList().then((val) => {
                nextTick(() => {
                    new DataTable('#categoriesTable', {
                        searching: true,
                        paging:true,
                        columnDefs:[{
                            "targets":4
                        }],
                    });
                });
            });
        })
        return {
            listOperators,
            pencilOutline,
            dataService,
        }
    },
    methods:{
        editProfile(key:string){
            this.$router.push({
                name:'editCategoryPage',
                params:{
                    id:key
                }
            })
        },
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
            message: msg,
            duration: 1500,
            position: position,
            cssClass: 'custom-toast',
            });

            await toast.present();
        },
        changeStatus(key:string, event:any){
            const result = this.dataService.changeStatus(key, event.detail.checked);
            result.then((response) => {
                if(response.status == 200) {
                    this.presentToast("bottom", 'Categoría modificada')
                }
            });
        }
    }
})
