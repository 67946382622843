
import { IonCheckbox, toastController, IonButton, loadingController } from '@ionic/vue';
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import DataTable from 'datatables.net-dt'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { DataService } from '@/services/security-profile/data.service';

export default defineComponent({
    name:'ProfileComponent',
    components:{
        IonCheckbox, IonButton
    },
    setup(){
        const dataService = new DataService()
        const listOperators = ref()
        const getList = async () => {
            listOperators.value = await dataService.fetchData()
        }
        onMounted(async () => {
            const loading = await loadingController.create({
                message: "Obteniendo datos"
            });
            loading.present()
            getList()
            nextTick(() => {
                loading.dismiss()
            })
        })
        return {
            listOperators,
            dataService
        }
    },
    updated(){
        nextTick(() => {
            new DataTable('#proveedorProductos', {
                searching: true,
                paging:true,
                columnDefs:[{
                    "targets":3
                }],
            })
        })
    },
    methods:{
        editProfile(key:string){
            this.$router.push({
                name:'editProfilePage',
                params:{
                    key:key
                }
            })
        },
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
            message: msg,
            duration: 1500,
            position: position,
            cssClass: 'custom-toast',
            });

            await toast.present();
        },
        async changeStatus(operatorId:string, event:any){
            const response = await this.dataService.changeStatus(operatorId, event.detail.checked);
            if(response.status == 200) {
                this.presentToast("bottom", 'Perfil modificado')
            } else{
                this.presentToast("bottom", 'Error al modificar')
            }            
        }
    }
})
