import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Base from '@/components/Base.vue'
import CategoryPage from '../views/CategoryPage.vue'
import newCategoryPage from '../views/categories/newCategoryPage.vue'
import editCategoryPage from '../views/categories/editCategoryPage.vue'
import OperadoresPage from '../views/OperadoresPage.vue'
import PerfilesSeguridadPage from '../views/PerfilesSeguridadPage.vue'
import newProfilePage from '../views/profileSecurity/newProfilePage.vue'
import newOperatorPage from '../views/operators/newOperatorPage.vue'
import editOperator from '../views/operators/editOperatorPage.vue'
import CitiesPage from '../views/CitiesPage.vue'
import newCityPage from '../views/cities/newCityPage.vue'
import editCityPage from '../views/cities/editCityPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/web/Home.vue')
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: () => import('@/views/web/Nosotros.vue')
  },
  {
    path: '/especialistas',
    name: 'Especialistas',
    component: () => import('@/views/web/Especialistas.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import('@/views/web/Contacto.vue')
  },
  {
    path: '/politicas',
    name: 'Politicas',
    component: () => import('@/views/web/Politicas.vue')
  },
  {
    path: '/privacidad',
    name: 'Privacidad',
    component: () => import('@/views/web/Privacidad.vue')
  },
  {
    path: '/devoluciones',
    name: 'Devolucion',
    component: () => import('@/views/web/Devolucion.vue')
  },
  {
    path: '/panel',
    redirect: '/panel/login'
  },
  {
    path: '/panel/login',
    name: 'Login',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/panel/forgot',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPasswordPage.vue')
  },
  {
    path: '/panel/dashboard',
    name: 'DashboardPage',
    component: () => import('@/views/DashboardPage.vue')
  },
  {
    path: '/panel/dashboard/categories',
    name: 'CategoryPage',
    component: Base,
    children: [
      {
        path:'',
        name:'ListCategories',
        component:CategoryPage
      },
      {
        path:'create',
        name:'newCategoryPage',
        component: newCategoryPage
      },
      {
        path:'edit/:id',
        name:'editCategoryPage',
        component: editCategoryPage
      },
    ]
  },
  {
    path: '/panel/dashboard/servicios',
    name: 'ServiciosPage',
    component: () => import('@/views/ServiciosPage.vue')
  },
  {
    path: '/panel/dashboard/ciudades',
    name: 'CitiesPage',
    component: Base,
    children: [
      {
        path:'',
        name:'CitiesPage',
        component:CitiesPage
      },
      {
        path:'create',
        name:'newCityPage',
        component: newCityPage
      },
      {
        path:'edit/:id/:name',
        name:'editCityPage',
        component: editCityPage
      },
    ]
  },
  {
    path: '/panel/dashboard/usuarios',
    name: 'UsuariosPage',
    component: () => import('@/views/UsuariosPage.vue')
  },
  {
    path: '/panel/dashboard/empresas',
    name: 'EmpresasPage',
    component: () => import('@/views/EmpresasPage.vue')
  },
  {
    path: '/panel/dashboard/patrones',
    name: 'PatronesPage',
    component: () => import('@/views/PatronesPage.vue')
  },
  {
    path: '/panel/dashboard/especialistas',
    name: 'EspecialistasPage',
    component: () => import('@/views/EspecialistasPage.vue')
  },
  {
    path: '/panel/dashboard/operadores',
    name: 'OperadoresPage',
    component: Base,
    children:[
      {
        path:'',
        name:'ListOperators',
        component:OperadoresPage
      },
      {
        path:'create',
        name:'newOperatorPage',
        component:newOperatorPage
      },
      {
        path:'edit/:id',
        name:'editOperatorPage',
        component:editOperator
      }
    ]
  },
  {
    path: '/panel/dashboard/perfiles-seguridad',
    name: 'PerfilesSeguridadPage',
    component: Base,
    children:[
      {
        path:'',
        name:'ListProfile',
        component: PerfilesSeguridadPage
      },
      {
        path:'create',
        name:'newProfile',
        component: newProfilePage
      },
      {
        path:'edit/:key',
        name:'editProfilePage',
        component: () => import('@/views/profileSecurity/editProfilePage.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
