import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c7efbc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center justify-center" }
const _hoisted_2 = { class: "flex flex-row flex-wrap mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_component = _resolveComponent("menu-component")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_menu_component),
    _createVNode(_component_ion_page, { id: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, { translucent: true }, {
          default: _withCtx(() => [
            _createVNode(_component_nav_bar, { label: "Crear Perfil de seguridad" })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { fullscreen: true }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Nombre ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    required: true,
                    modelValue: _ctx.operatorData.nombre,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.operatorData.nombre) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Permisos ")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listPermissions, (permisos) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: permisos.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_checkbox, {
                            slot: "start",
                            onIonChange: ($event: any) => (_ctx.selectPermission(permisos, $event)),
                            checked: permisos.checked
                          }, null, 8, ["onIonChange", "checked"]),
                          _createVNode(_component_ion_label, { class: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(permisos.nombre), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "mt-4",
                onClick: _ctx.saveOperator
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Guardar")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}