
import { IonPage, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent( {
    name:'BaseComponent',
    components:{
        IonPage, IonRouterOutlet
    }
})
