
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, 
    IonInput, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios'
import { useRouter } from 'vue-router';
import { Operator } from '@/interfaces/OperatorInterface';
export default defineComponent({
    name:"newOperatorPage",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, 
        IonButton, IonSelect, IonSelectOption
    },
    setup() {
        const router = useRouter()
        const getList = () => {
            return axios.get(
                `${process.env.VUE_APP_URL_FIREBASE}/getProfiles`
            )
        }
        const getListCities = () => {
            return axios.get(
                `${process.env.VUE_APP_URL_FIREBASE}/getCities`
            )
        }
        const profilesList = ref([] as any)
        const ciudadList = ref([] as any)
        const operatorData = ref({} as Operator)

        onMounted(() => {

            getList()
            .then((response) => {
                profilesList.value = response.data.profiles
            }) 
            getListCities()
            .then((response) => {
                ciudadList.value = response.data.cities
            })            

        })

        return {
            router,
            operatorData,
            profilesList,
            ciudadList
        }
    },
    methods:{
        selectCiudad(event:any){
            this.operatorData.ciudad = event.detail.value
        },
        selectProfile(event:any){
            this.operatorData.perfil = event.detail.value
        },
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
                message: msg,
                duration: 1000,
                position: position,
                cssClass: 'custom-toast',
            });

            await toast.present();
        },
        async saveOperator(){
            if(this.operatorData.nombre.length == 0 || this.operatorData.correo.length == 0 || this.operatorData.telefono.length == 0) {
                this.presentToast("bottom", "Ingrese toda la información")
                return
            }
            return await axios.post(
                `${process.env.VUE_APP_URL_FIREBASE}/saveOperator`,
                this.operatorData
            ).then((response) => {
                if(response.status == 200) {
                    this.presentToast("bottom", 'Operador creado exitosamente')
                    this.$router.push({
                        name:'OperadoresPage',
                        replace:true
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
})
