import axios from 'axios';

export class DataService {
    private apiUrl = process.env.VUE_APP_URL_FIREBASE
    async getData() {
        const response = await axios.get(`${this.apiUrl}/getCities`)
        return response.data.cities;
    }
    async saveCity(data:any) {
        const response = await axios.post(`${this.apiUrl}/saveCity`, data);
        return response;
    }
    async editCity(data:any) {
        const response = await axios.put(`${this.apiUrl}/editCity`, data);
        return response;
    }
    async changeStatus(key:string, status:any) {
        const response = axios.put(`${this.apiUrl}/changeCityStatus`, {
            'id': key,
            'status': status
        });
        return response;
    }
}