
import { IonButton, IonContent, IonHeader, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { personCircleOutline} from 'ionicons/icons';
import Operadores from '../components/operators/Operadores.vue'
export default defineComponent({
    name: 'OperadoresPage',
    components:{
        IonContent, IonHeader, IonPage, IonButton, Operadores
    },
    setup(){
        const router = useRouter()
        return {
            router,
            personCircleOutline
        }
    },
    methods:{
        createOperator(){
            this.router.push({
                name:'newOperatorPage',
                replace:true
            })
        }
    }
})
