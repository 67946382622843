
import { IonButton, IonContent, IonHeader, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { personCircleOutline} from 'ionicons/icons';
import ProfileComponent from '../components/profileSecurity/Profile.vue'
export default defineComponent({
    name: 'PerfilesSeguridadPage',
    components:{
        IonContent, IonHeader, IonPage, IonButton, ProfileComponent
    },
    setup(){
        const router = useRouter()
        return {
            router,
            personCircleOutline
        }
    },
    methods:{
        newProfile(){
            this.$router.push({ name:'newProfile'})
        },
    }
})
