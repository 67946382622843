import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_buttons, { slot: "start" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu_button)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { slot: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "mr-2" }, {
            default: _withCtx(() => [
              _createTextVNode(" Hola, " + _toDisplayString(_ctx.user.nombre), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { slot: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            class: "ml-2 mr-4",
            icon: _ctx.personCircleOutline
          }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}