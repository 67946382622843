import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_component = _resolveComponent("menu-component")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_operadores = _resolveComponent("operadores")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_menu_component),
    _createVNode(_component_ion_page, { id: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, { translucent: true }, {
          default: _withCtx(() => [
            _createVNode(_component_nav_bar, { label: "Operadores" })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_button, {
                  onClick: _ctx.createOperator,
                  class: "m-2",
                  size: "large",
                  color: "light"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Crear")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _createVNode(_component_operadores)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}