import { createApp, watch } from 'vue'
import { createPinia } from 'pinia';
import { VueFire } from 'vuefire'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import './theme/global.scss';
import 'aos/dist/aos.css'

import LayoutNav from './components/web/LayoutNav.vue'
import LayoutFooter from './components/web/LayoutFooter.vue'
import NavBar from './components/NavBar.vue'
import Menu from './components/Menu.vue'

import { firebaseApp } from './config/firebase';
import Notifications from '@kyvg/vue3-notification'

import filters from './helpers/filters'

const pinia = createPinia()

watch(
  pinia.state,
  (state) => {
    localStorage.setItem("operator", JSON.stringify(state.operator))
  },
  {
    deep:true
  }
)

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueFire, {
    firebaseApp
  })
  .use(Notifications)
  .use(pinia)
  .component('menu-component', Menu)
  .component('nav-bar', NavBar)
  .component('layout-nav', LayoutNav)
  .component('layout-footer', LayoutFooter);

app.config.globalProperties.$filters = filters;
router.isReady().then(() => {  
  app.mount('#app');
});