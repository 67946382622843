import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-424337ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_component = _resolveComponent("menu-component")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_menu_component),
    _createVNode(_component_ion_page, { id: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, { translucent: true }, {
          default: _withCtx(() => [
            _createVNode(_component_nav_bar, { label: "Editar Operador" })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { fullscreen: true }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Nombre ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    required: true,
                    modelValue: _ctx.operatorData.nombre,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.operatorData.nombre) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Apellido ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    required: true,
                    modelValue: _ctx.operatorData.apellido,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.operatorData.apellido) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Teléfono ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "number",
                    required: true,
                    modelValue: _ctx.operatorData.telefono,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.operatorData.telefono) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Correo electrónico ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "email",
                    required: true,
                    modelValue: _ctx.operatorData.correo,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.operatorData.correo) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Password ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "password",
                    required: true,
                    modelValue: _ctx.operatorData.password,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.operatorData.password) = $event)),
                    modelModifiers: { lazy: true }
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Ciudad ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { class: "flex mt-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectProfile($event))),
                        placeholder: "Selecciona una ciudad"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityList, (value, key) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: key,
                              value: key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(value.nombre), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Perfil ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { class: "flex mt-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectProfile($event))),
                        placeholder: "Selecciona un perfil de seguridad"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profilesList, (value, key) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: key,
                              value: key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(value.nombre), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "mt-4",
                onClick: _ctx.saveOperator
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Guardar")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}