import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5995a0ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_component = _resolveComponent("menu-component")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_menu_component),
    _createVNode(_component_ion_page, { id: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, { translucent: true }, {
          default: _withCtx(() => [
            _createVNode(_component_nav_bar, { label: "Crear categoría" })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { fullscreen: true }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Nombre ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    id: "nombre",
                    required: true,
                    modelValue: _ctx.operatorData.nombre,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.operatorData.nombre) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { class: "mx-auto mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Icono ")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("input", {
                    type: "file",
                    name: "image",
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFileChange($event))),
                    accept: "image/png"
                  }, null, 32)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "mt-4",
                onClick: _ctx.saveCategory
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Guardar")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}