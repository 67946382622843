
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, IonInput, IonCheckbox, IonItem } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import axios from 'axios'
import { useRouter } from 'vue-router';
export default defineComponent({
    name:"newProfilePage",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonCheckbox, IonItem, IonButton
    },
    setup() {
        const router = useRouter()
        const selectPermissions:any = []
        const listPermissions = [
            {
                id:"categories",
                nombre:"Categorias",
                checked: false,
            },
            {
                id:"services",
                nombre:"Servicios",
                checked: false,
            },
            {
                id:"users",
                nombre:"Usuarios",
                checked: false,
            },
            {
                id:"companies",
                nombre:"Empresas",
                checked: false,
            },
            {
                id:"citys",
                nombre:"Ciudades",
                checked: false,
            },
            {
                id:"specialists",
                nombre:"Especialistas",
                checked: false,
            },
            {
                id:"operators",
                nombre:"Operadores",
                checked:false
            },
            {
                id:"profileSecurity",
                nombre:"Perfiles de seguridad",
                checked:false
            }
        ]
        const operatorData = ref({
            nombre: "",
            permisos: {} as any
        })

        return {
            router,
            operatorData,
            listPermissions,
            selectPermissions
        }
    },
    methods:{        
        async presentToast(position:'bottom') {
            const toast = await toastController.create({
            message: 'Perfil creado exitosamente',
            duration: 1500,
            position: position,
            cssClass: 'custom-toast',
            });

            await toast.present();
        },
        selectPermission(data:any, event:any){
            if(event.detail.checked == true) {
                if(data.id in this.operatorData.permisos) {
                    delete this.operatorData.permisos[data.id]
                } else {
                    this.operatorData.permisos[data.id] = event.detail.checked
                }
            } else {
                delete this.operatorData.permisos[data.id]
            }
        },
        async saveOperator(){
            return await axios.post(
                `${process.env.VUE_APP_URL_FIREBASE}/saveProfile`,
                this.operatorData
            ).then((response) => {
                if(response.status == 200) {
                    this.presentToast("bottom")
                    setTimeout(() => {
                        this.router.back()
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
})
