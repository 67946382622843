
import { IonTitle, IonToolbar, IonLabel, IonButtons, IonIcon, IonMenuButton, IonBackButton} from '@ionic/vue';
import { defineComponent } from 'vue';
import { personCircleOutline} from 'ionicons/icons';
import { userStore } from '@/store/userStore';
export default defineComponent({
    name:'NavBar',
    components:{
        IonTitle, IonToolbar, IonLabel, IonIcon, IonButtons, IonMenuButton
    },
    props:['label', 'back'],
    setup(){
        const store = userStore()
        const user = store.getUser
        return {
            personCircleOutline,
            store,
            user
        }
    }
})
