import { defineStore } from "pinia";
import { Operator } from "@/interfaces/OperatorInterface";
import axios from 'axios'

//https://us-central1-iduit-91cb7.cloudfunctions.net/loginWeb
const endpoint = `${process.env.VUE_APP_URL_FIREBASE}/login`
export const userStore = defineStore("operator", {
    state: () => {
        if(localStorage.getItem("operator")) {
            return JSON.parse(localStorage.getItem("operator") || "{}")
        }
        return {
            user: {} as Operator
        }
    }, 
    getters:{
        getUser: (state) => state.user
    },
    actions:{
        async login(email:string, pwd:string) {
            const response = await axios.post(
                endpoint,
                {
                    'correo': email,
                    'password': pwd
                }, {
                    headers: {
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            )
            console.log(response.data);
            if(response.status === 200) {
                this.user = response.data
                localStorage.setItem("operator", JSON.stringify(this.user))
                return true
            } else {
                return false
            }
        }
    }
})