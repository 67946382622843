import axios from 'axios';

export class DataService {
    private apiUrl = process.env.VUE_APP_URL_FIREBASE

    async getData(id:any) {
        const response = await axios.get(`${this.apiUrl}/getCategory`,{
            params:{
                id:id
            }
        })
        return response;
    }

    async fetchData() {
        const response = await axios.get(`${this.apiUrl}/getCategories`)
        return response.data.categories
    }

    async saveCategory(data:any) {
        const response = axios.post(`${this.apiUrl}/saveCategory`, data, {
            headers: {
                "Content-Type" : "application/x-www-form-urlencoded"
            }
        });
        return response;
    }

    async changeStatus(key:string, status:any) {
        const response = axios.put(`${this.apiUrl}/changeCategoryStatus`, {
            'id': key,
            'status': status
        });
        return response;
    }
}