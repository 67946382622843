
import { IonToggle, toastController, IonButton, IonIcon, loadingController } from '@ionic/vue';
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import DataTable from 'datatables.net-dt'
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import { createOutline } from 'ionicons/icons';

export default defineComponent({
    name:'OperadoresComponent',
    components:{
        IonToggle, IonIcon, IonButton
    },
    setup(){
        const router = useRouter()
        const listOperators = ref(null)
        const filterStatus = ref<boolean | null>(null)        
        const getList = () => {
            return axios.get(
                `${process.env.VUE_APP_URL_FIREBASE}/getOperators`
            )
        }
        onMounted(async () => {    
            const loading = await loadingController.create({
                message: 'Obteniendo datos'
            })    ;
            loading.present();    
            getList()
            .then((response) => {
                listOperators.value = response.data.operators
                nextTick(() => {
                    new DataTable('#proveedorProductos', {
                        searching: true,
                        columnDefs:[{
                            "targets":6
                        }],
                    })
                })
                loading.dismiss()
            })            
            
        })
        function filterByStatus(status:boolean|null) {            
            filterStatus.value = status            
        }
        return {
            router,
            listOperators,
            createOutline,
            filterStatus,
            filterByStatus
        }
    },
    methods:{
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
            message: msg,
            duration: 1500,
            position: position,
            cssClass: 'custom-toast',
            });

            await toast.present();
        },
        changeStatus(operatorId:string, event:any){
            return axios.post(
                "https://us-central1-iduit-91cb7.cloudfunctions.net/modifyOperator",
                {
                    operatorId:operatorId,
                    status:event.detail.checked
                }
            )
            .then((result) => {
                if(result.status == 200) {
                    this.presentToast("bottom", 'Operador modificado')
                }
            })
            .catch(() => {
                this.presentToast("bottom", 'Error al modificar')
            })
        },
        editOperator(id:string){
            this.router.push({
                name:'editOperatorPage',
                params:{
                    id:id
                },
                replace:true
            })
        }
    }
})
