
import { toastController, IonButton, IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { DataService } from '@/services/cities/data.service';
export default defineComponent({
    name:"editCityPage",
    components:{
        IonContent, IonHeader, IonPage, IonLabel, IonInput, IonItem, IonButton
    },
    setup() {
        const dataService = new DataService()      
        const router = useRouter()
        const route = useRoute()
        const id = route.params.id
        const name = route.params.name
        const operatorData = ref({
            nombre: "",
        });
        onMounted(() => {
            operatorData.value.nombre = name.toString()
        });

        return {
            router,
            operatorData,
            dataService,
            id,
            name
        }
    },
    methods:{       
        
        async presentToast(position:'bottom', msg:string) {
            const toast = await toastController.create({
            message: msg,
            duration: 1500,
            position: position,
            });

            await toast.present();
        },
        async saveCategory () {               
            const response = await this.dataService.editCity({
                "id" : this.id,
                "nombre" : this.operatorData.nombre,
            });
            if(response.status == 200) {
                this.presentToast("bottom", "Ciudad guardada");
                setTimeout(() => {
                    this.$router.replace({
                        name:'CitiesPage',
                    })
                }, 1000);
            } else {
                this.presentToast("bottom", "Problemas");
            }
        }
    }
})
